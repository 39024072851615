.menu-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 129px;
	background: #ffffff;
	box-shadow: 0px 4px 4px #e5e5e5;
	top: 0;
	position: sticky;
}

.logo {
	margin-left: 120px;
}
.nav {
	display: flex;
	align-items: center;
	margin-right: 120px;
}
/*
.home {
	margin-right: 51px;
} */

.members {
	margin-right: 46px;
}

.nav a {
	text-decoration: none;
	display: block;
	float: left;
	display: block;
	text-align: center;
	padding-bottom: 15px;
	text-decoration: none;
	font-size: 17px;
	border-bottom: 3px solid transparent;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #617687;
	margin-right: 50px;
}

.nav a:hover {
	border-bottom: 3px solid black;
	color: black;
}

.nav a.active {
	border-bottom: 3px solid black;
	color: black;
}

@media (max-width: 415px) {
	.menu-bar {
		display: none;
	}
}
