#user-img {
	margin-right: 16px;
}

#snackbar {
	visibility: hidden;
	min-width: 100%;

	padding: 16px;
	z-index: 1;
	top: 0;

	/*   
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 22px;
  color: #000000; */
}

#snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 30px;
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 30px;
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}
	to {
		bottom: 0;
		opacity: 0;
	}
}

@keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}
	to {
		bottom: 0;
		opacity: 0;
	}
}
.PostSomething {
	background: #ffffff;
	border: 1px solid #c4c4c4;
	border-radius: 2px;
	height: 69px;
	display: flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	width: 100%;
	padding-bottom: 5px;
}

.post-something {
	text-align: left;
	width: 75%;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	border-radius: 2px;
	padding-top: 6px;
	padding-bottom: 6px;
	padding-left: 20px;
	color: #4a4a4a;
	margin-left: -7px;
	margin-top: 5px;
}
#post-something {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
}

.mobile-user-img {
	height: 35px;
	width: 35px;
	margin-left: 22px;
	margin-right: 15px;
}

.create-a-post {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin-left: 25%;
}

.post-contents {
	padding-left: 20px;
	padding-right: 20px;
}

#modal-title {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-left: -30px;
	padding-left: 25px;
}

.create-a-post img {
	height: 16px;
	width: 16px;
	padding-left: 100px;
}

/* modal */

#user-name {
	font-weight: bold;
	font-size: 14px;
	line-height: 19px;
}

.user-pic {
	float: left;
	margin-right: 15px;
	height: 50px;
	width: 50px;
}

#lock {
	margin-right: 4px;
	height: 10px;
	width: 10px;
}
#visibility-text {
	font-size: 13px;
}
.topic {
	margin-top: 25px;
	margin-left: 5px;
}
.custom-search-select {
	/* padding: 5px; */
	margin-top: 5px;
	/* margin-bottom: 15px; */
	border: 1px solid #4a4a4a;
	border-radius: 5px !important	;
	width: 97.9%;
	height: 42px;
	color: #4a4a4a;
	background: white;
	padding-left: 10px;
}

#title-input {
	padding: 5px;
	margin-top: 5px;
	border: 1px solid #4a4a4a;
	border-radius: 5px;
	width: 92%;
	height: 42px;
	font-family: 'Lato';
	padding-top: 10px;
	padding-left: 10px;
	color: #4a4a4a !important;
}

#description-input {
	width: 97%;
	min-height: 120px;
	background: #ffffff;
	border: 1px solid #4a4a4a;
	box-sizing: border-box;
	border-radius: 2px;
	font-family: 'Lato';
	padding-top: 10px;
	padding-left: 10px;
	border-radius: 5px !important;
}

#attach-btn {
	width: 65%;
	margin-top: 5px;
	margin-bottom: 15px;
}

#post-btn {
	width: 98%;
}

#post-modal {
	background: #f5f5f5 !important;
	border: 1px solid #c4c4c4 !important;
	box-sizing: border-box;
	padding-bottom: 20px !important;
	width: 100% !important;
	height: 100% !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	transform: translate(-11%);
}
#topic {
	padding-left: 0.5px;
	font-weight: bold;
	font-size: 14px;
}

#title {
	padding-left: 5px;
	font-weight: bold;
	font-size: 14px;
}

#post-img {
	margin-left: 23px;
}
@media (min-width: 415px) {
	body {
		width: 53%;
	}

	.post-contents {
		padding: 0;
	}
	#post-btn {
		width: 97%;
		margin-bottom: 20px;
	}
	#attach-btn {
		width: 223px;
		height: 42px;
	}
	.create-a-post {
		display: flex;
		margin-left: -50px;
		justify-content: space-between;
		font-weight: bold;
		font-size: 24px;
		line-height: 28.8px;
		color: #000000;
		padding-bottom: 5px;
	}

	#post-modal {
		width: 50% !important;
		background: #f5f5f5 !important;
		border-radius: 15px !important;
		/* padding-bottom: 20px !important; */
		height: auto !important;
		max-height: 644px !important;
		/* transform: translate(-30%, 20%) !important; */
		padding-top: 0;
		padding-left: 60px !important;
		padding-right: 40px !important;
	}

	.custom-search-select {
		width: 50%;
		height: 34px;
	}

	#title-input {
		height: 28px;
		width: 80%;
		padding-left: 5px;
	}
	#description-input {
		min-height: 100px;
		width: 97%;
		padding-left: 5px;
		margin-top: -10px;
		border-radius: 15px !important;
	}
	#user-name {
		font-size: 16px;
	}
	#visibility-text {
		font-size: 16px;
	}
	.post-something {
		width: 82%;
		margin-left: 0;
		margin-right: 10px;
		margin-top: 0;
	}
	#modal-title {
		font-weight: bold;
		font-size: 24px;
		line-height: 29px;
		margin-left: 30px;
	}
	#topic {
		padding-left: 0.5px;
		font-weight: bold;
		font-size: 16px;
	}
	#title {
		font-size: 16px;
	}
	.topic {
		margin-top: 25px;
		margin-left: 5px;
	}
	.title {
		margin-top: -10px;
	}
	.create-a-post img {
		height: 20px;
		width: 20px;
	}
	#post-img {
		margin-left: 20px;
	}
}
