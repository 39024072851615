.menu-mobile {
	background: #ffffff;
	box-shadow: 0px 4px 4px #e5e5e5;
}

.mobile-menu-top {
	max-width: 100%;
	margin: 0 auto;
}

#guideline-text {
	background: #617687;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	margin: 0;
	text-align: center;
	color: #ffffff;
	padding-top: 8px;
	padding-bottom: 12px;
}
#guideline-link {
	text-decoration: underline;
	text-decoration-color: white;
}

.mobile-menu-top {
	display: flex;
	justify-content: center;
}

#mobile-user {
	margin-right: -100px;
	margin-top: 20px;
}

.mobile-nav {
	display: flex;
	justify-content: space-around;
	align-items: center;
	/* padding-bottom: 10px; */
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
}
.mobile-nav a {
	text-decoration: none;
	color: #617687;
	text-decoration: none;
	display: block;
	float: left;
	display: block;
	text-align: center;
	padding-bottom: 15px;
	text-decoration: none;
	font-size: 17px;
	border-bottom: 3px solid transparent;
	font-weight: bold;
	font-size: 18px;
	width: 50%;
}

.mobile-nav a:hover {
	border-bottom: 3px solid black;
	color: black;
}

.mobile-nav a.active {
	border-bottom: 3px solid black;
	color: black;
}

@media (min-width: 415px) {
	.menu-mobile {
		display: none;
	}
	Modal {
		display: none;
	}
}
