body {
	background: #f5f5f5;
}

a {
	text-decoration: none !important;
}
.home-right {
	display: flex;
	width: 100%;
	text-align: left;
}

.welcome {
	font-weight: bold;
	font-size: 22px;
	line-height: 120%;
	text-align: center;
	color: #16636c;
}
.row {
	width: 100%;
}

.home-right {
	display: none;
}
#discussion-title {
	padding-left: 20px;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
}
@media (min-width: 415px) {
	.home {
		margin-left: 140px;
		margin-right: 140px;
	}

	.welcome {
		text-align: left;
	}

	.row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
	}
	.home-left {
		width: 65%;
	}
	.home-right {
		flex-direction: row;
		flex-basis: 100%;
		flex: 1;
		display: inline-block;
		margin-left: 35px;
		margin-top: 70px;
		width: 30%;
	}
	#discussion-title {
		padding-left: 0px;
	}
}
