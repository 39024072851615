#guideline-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent !important;

	/* position: absolute; */
	/* top: 0%;
	left: 0%; */
	/* transform: translate(-5%, 29%) !important; */
	/* border: 1px solid #a4a4a4 !important; */
	/* box-sizing: border-box !important; */
	/* border-radius: 15px !important; */
	/* width: 90% !important;
	height: 65% !important; */
	font-size: 16px;
	line-height: 22px;
	color: #000000;
}

.guidelines-wrapper {
	background-color: white;
 	width: 90% !important;
	/* height: 65% !important; */
	border-radius: 15px !important;
	
}

.guidelines-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	padding: 20px;
	padding-bottom: 0;
}

.guidelines-btn {
	text-align: center;
}

#guidelines-btn {
	width: 85%;
	padding-left: 5px;
	padding-right: 5px;
	font-size: 16px;
	margin-bottom: 30px;
}
.guidelines-content {
	font-size: 16px;
	line-height: 25px;
	padding: 20px;
}

@media (max-width: 415px) {
	body {
		width: 88%;
	}
	h3 {
		font-weight: bold;
		font-size: 20px;
		line-height: 24px;
	}
	#guidelines-btn {
		width: 95%;

		padding-left: 5px;
		padding-right: 5px;
		font-size: 16px;
	}
	.guidelines-wrapper {
		border: 1px solid #a4a4a4 !important;

	}
}

/* .guidelines-content {
	font-size: 16px;
	line-height: 25px;
	margin-bottom: 30px;
} */
