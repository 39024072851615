@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

body {
	width: 100%;
	margin: 0 auto;
	font-family: 'Lato';
}

.bold {
	font-weight: 700;
}
.App {
	text-align: left;
	font-family: 'Lato';
}
#sp {
	justify-content: space-between;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;

	padding: 10px;
}
#request-img {
	height: 35px;
	margin-top: 10px;
}
body {
	background: #f5f5f5;
}
#ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 0;
}

.home-right {
	display: flex;
	width: 38%;
	text-align: left;
}

.home-right-members {
	/* display: flex;
	width: 38%;
	text-align: left; */
	background-color: white;
	display: none;
}
#members-modal {
	height: 60% !important;
	width: 90% !important;
	position: fixed !important;
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;
}

#members-modal2 {
	height: 60% !important;
	width: 90% !important;
	position: fixed !important;
	left: 50% !important;
	top: 50% !important;
	transform: translate(-50%, -50%) !important;
}

.welcome {
	font-weight: bold;
	font-size: 22px;
	line-height: 120%;
	text-align: center;
	color: #16636c;
}
.row {
	width: 100%;
}
.memeber-card {
	width: 100%;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	padding: 13px 13px 13px 13px;
}

.home-right {
	display: none;
}
#discussion-title {
	padding-left: 20px;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
}
.request {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	padding: 20px;
	padding-bottom: 0;
}
#comment-title {
	font-size: 20px;
	font-weight: 700;
	padding-left: 40px;
}

.card-title-post {
	display: flex;
	flex-direction: row;
	align-content: center;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #000000;

	justify-content: space-evenly;
	align-items: flex-start;
	flex-wrap: nowrap;
}

.mobile-user-img-post {
	height: 35px;
	margin-top: 15px;
	margin-left: 0;
}
.topic-title {
	margin-bottom: 0;
	margin-top: 16px;
}
#c-img {
	margin-top: 19px;
}
.category-1st {
	margin-right: 10px;
	margin-left: 22px;
}
.category-2nd {
	margin-left: 10px;
}
.list {
	display: flex;
	margin-top: -8px;
	/* margin-left: -40px; */
}
.category {
	text-decoration: none;
	line-height: 22px;
	font-weight: 500;
	color: #000000;
	margin-top: 16px;
	margin-bottom: -15px;
}
#post-intro {
	margin-top: 0;
	margin-bottom: 0;
}

#btm16 {
	margin-bottom: 16px;
}

#se {
	justify-content: space-evenly;

	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	padding-top: 13px;
	padding-bottom: 13px;
}

#visibility-connection {
	display: flex;
	padding-left: 20px;
	padding-right: 12px;

	justify-content: flex-start;
	align-items: center;
	background: #ffffff;
}

#filter-btn {
	margin-bottom: 16px;
	margin-left: 16px;
	width: 90%;
}

.add-Picture {
	display: flex;
}
.edit-img {
	height: 24px;
	width: 24px;
	margin-top: 20px;
	margin-left: 15px;
}
.flex {
	display: flex;
}
.member-btn {
	width: 41%;
	margin: 10px 3px 10px 20px;
}
.about-x {
	margin-left: 87%;
	margin-top: 5%;
}
.about-profile {
	margin-top: -5%;
}

@media (min-width: 415px) {
	#filter-btn {
		margin-left: 0;
		width: 200px;
	}

	#ellipsis {
		margin-left: 16px;
	}
	.margin-left {
		margin-left: 5px;
	}
	.member-btn {
		display: none;
	}
	.home {
		margin-left: 140px;
		margin-right: 140px;
	}
	#hide {
		display: none;
	}
	#comment-title {
		padding-left: 20px;
	}

	.post-context {
		height: 50px;
		margin-top: 17px;
	}
	/* #post-date {
		display: inline-block;
	} */
	.welcome {
		text-align: left;
	}

	.row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;
	}
	.home-left {
		width: 62%;
	}
	.home-right {
		flex-direction: row;
		flex-basis: 100%;
		flex: 1;
		display: inline-block;
		margin-left: 80px;
		margin-top: 70px;
		background: #ffffff;
		border: 1px solid #c4c4c4;
		box-sizing: border-box;
		border-radius: 5px;
		width: 35%;
	}
	.home-right-members {
		flex-direction: row;
		flex-basis: 100%;
		flex: 1;
		display: inline-block;
		margin-left: 80px;
		margin-top: 70px;
		background: #ffffff;
		border: 1px solid #c4c4c4;
		box-sizing: border-box;
		border-radius: 5px;
		width: 35%;
	}
	#discussion-title {
		padding-left: 0px;
	}
}

button {
	/* font-weight: 600; */
	text-align: center;
	font-size: 16px;
	color: #ffffff;
	line-height: 19px;
	text-align: center;
	background-color: #16636c;
	height: 42px;
	border: none;
	border-radius: 300px;
	cursor: pointer;
}

button.secondary {
	border: 2px solid #16636c;
	background-color: #ffffff;
	color: #16636c;
}

.input-title {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	margin-bottom: 5px;
}
.input-box {
	background: #ffffff;
	border: 1px solid #4a4a4a;
	border-radius: 2px;
	width: 97%;
	height: 34px;
}
.ReactModal__Content.ReactModal__Content--after-open {
	padding: 0 !important;
	background-color: white !important;
	width: 100% !important;
	height: 100% !important;
	transform: none !important;
	top: 0% !important;
	left: 0% !important;
	padding: 0 !important;
	border-radius: 15px !important;
}
#add-modal {
	margin-left: 9%;
	width: 90%;
}
#contact {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
}

.name {
	font-weight: bold;
	line-height: 24px;
	color: #16636c;
	margin-bottom: 5px;
	margin-top: -5px;
}
.post-visibility {
	display: flex;
	align-content: center;
	align-items: flex-end;
}
.country {
	margin-top: 16px;
}

.profile-img {
	width: 50px;
	height: 50px;
	border-radius: 300px;
}

.profile-img-name {
	display: flex;
	margin: 16px 26px;
	align-items: center;
	word-break: break-all;
}

.profile-img-name > img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.category {
	/* font-size: 16px; */
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: 10px;
}
#save-btn {
	width: 50%;
	margin-left: 25%;
}
#add-hobby {
	margin-left: 30px;
	margin-right: 10px;
}
#visibility-top {
	margin-left: 8%;
	padding-right: 0 !important;
	max-width: 255px;
	display: flex;
	margin-top: 5px;
	padding-left: 16px;
	justify-content: flex-start;
	align-items: center;
	background: #ffffff;
	border: 1px solid rgba(74, 74, 74, 0.8);
	border-radius: 5px;
}

.check-box {
	margin-top: 10px;
	margin-left: 40px;
	margin-right: 10px;
}

#check-subtitle {
	font-weight: bold;
	font-size: 17px;
	line-height: 19px;
	color: #000000;
	margin-bottom: 25px;
	width: 95%;
}
.check-subtitle {
	font-weight: bold;
	font-size: 17px;
	margin-left: 20px;
	color: #000000;
	margin-top: 25px;
	margin-right: 10px;
}
/* .custom-search-select country-option {
	background: #ffffff;
	border: 1px solid #4a4a4a;
	border-radius: 2px;
} */
.save-btn {
	width: 100%;
	padding-top: 28px;
	padding-bottom: 60px;
	background: #ffffff;
}
#save {
	width: 50%;
	margin: 0 auto;
	display: block;
}

.NamePlusProfileImage {
	margin-right: 30px;
	display: flex;
}

.user-name-container {
	margin: 0;
}
#name-NamePlusProfileImage {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-top: 11px;
	color: #16636c;
	margin-left: 10px;
}
/* .user-pic-container {
	margin-right: 10px;
} */
.occupation-country {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.contact-info-btn {
	margin-left: -7px;
	margin-top: 6px;
	margin-bottom: 6px;
	cursor: pointer;
}

#contact-container {
	overflow-x: wrap;
	font-size: 16px;
	margin-left: -10px;
}

#contact-container span {
	font-weight: normal;
}

.contact-description {
	margin: 10px;
	word-break: break-word;
}

.contact-label {
	font-weight: bold;
	margin-right: 10px;
	text-transform: capitalize;
}

.commentCount {
	text-align: end;
	padding-right: 30px;
	font-size: 14px;
}
#connect-lock {
	margin-right: 7px;
	margin-top: -40px;
	height: 20px;
	width: 15px;
}
.about-me-wrapper {
	margin: 26px;
	/* margin-left: 16px;
	margin-right: 27px; */
	width: 87%;
	/* position: fixed; */
	left: 6%;
}
.about-desc {
	font-size: 16px;
	line-height: 22px;
	padding-left: 15px;
	padding-right: 55px;
}
table {
	margin-top: 16px;
}
td {
	padding-left: 15px;
	padding-right: 16px;
}
.about-me {
	font-size: 14px;
	padding-bottom: 20px;
}

.about-me-box {
	border: 1px solid #4f4f4f;
	border-radius: 2px;
	width: fit-content;
	padding: 6px;
	margin-left: 16px;
	margin-bottom: 0;
	/* float: left; */
}
.edit-me-box {
	border: 1px solid #4f4f4f;
	padding: 6px;

	/* border-radius: 2px;
	width: fit-content;
	padding: 6px;
	margin-right: 16px;
	margin-bottom: 0;  */
}
.mgbtm0 {
	margin-bottom: 0;
}

.edit-list {
	justify-content: space-between;
	display: flex;
	margin-top: -8px;
	margin-right: 10px;
	flex-wrap: wrap;
}

.subtitle {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 0;
	/* margin-left: 0px; */
}

.checkboxTitle {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
}
.title-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	background-color: white;
	padding-top: 15px;
	padding-bottom: 15px;
}
#other {
	width: 80%;
	margin-left: 30px;
	padding: 5px;
	margin-bottom: 30px;
}
.file-list-container a {
	display: block;
	margin: 10px 0;
}
#attach-btn {
	margin-top: 16px;
}
#checkbox-x {
	margin-right: -110px;
	margin-top: -20px;
}
#create-profile-title {
	margin-left: 30px;
	font-weight: bold;
	font-size: 24px;
	line-height: 29px;
	color: #000000;
	margin-bottom: 15px;
}
.discussion-topic {
	width: 100%;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	margin-bottom: 14px;
	padding-left: 22px;
	padding-right: 13px;
}
.discussion-topic a {
	text-decoration: none;
	color: #000000;
}
.post-count {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #4a4a4a;
	margin-top: 0;
}

.card-title {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;

	color: #000000;
}

.card-title img {
	margin-right: 13px;
	float: left;
}
.post-context {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	margin-left: 7%;
}
/* .parent {
	 width: 100%;
	 display: flex;
} */

.card a {
	color: #000000;
}

.card-text,
.card-body {
	/* width: 100%; */
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
}

.card-body {
	margin-top: 1em;
	margin-bottom: 1em;
}

.post-date {
	margin: 0px;
	font-weight: 400;
}

.modal-box {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-height: 30%;
	width: 60%;
	overflow-y: auto;
	padding: 0;
}
.topic-option {
	margin-left: -5px;
}

#post-visibility-text {
	font-size: 12px;
}
.visibility {
	display: flex;
	margin-top: 5px;
	margin-bottom: 26px;
	padding-left: 16px;
	justify-content: flex-start;
	align-items: center;
	background: #ffffff;
	border: 1px solid rgba(74, 74, 74, 0.8);
	border-radius: 5px;
	width: 85%;
}
.form-wrapper {
	background: #f5f5f5;
}
#visibility-text {
	font-size: 12px;
	font-weight: normal;
	padding-right: 12px;

	/* padding-right: 12px; */
}

#connect-visibility {
	width: 90%;
}
.addPicture {
	display: flex;
	margin-left: 30px;
	/* margin-bottom: 15px; */
	margin-bottom: -30px;
	font-weight: bold;
	font-size: 16px;
	line-height: 29px;
	color: #000000;
	margin-top: 15px;
}
#addPicture-text {
	margin-left: 15px;
}
#visibility-profile {
	font-size: 13px;
	margin-left: 5px;
}

.another-box {
	margin-bottom: 10px;
}

/* .another {
	width: 97%;
	height: 35px;
} */

.another-btn {
	width: 80px;
	height: 30px;
	margin-top: 5px;
	background: #ffffff;
	border: 2px solid #16636c;
	border-radius: 300px;
	color: #16636c;
	font-size: 14px;
	font-weight: 400;
}
#add-avatar {
	height: 50px;
}
.ul {
	margin: 0;
	padding: 0;
}
/* #post-date {
	display: none;
} */
#about-input {
	height: 150px;
	padding-top: 16px;
	padding-left: 16px;
	height: 150px;
	padding-top: 16px;
	padding-left: 16px;
	font-family: 'Lato';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
}

#other-title {
	margin-left: 10%;
}

#lock {
	margin-right: 7px;
	margin-top: -10px;
	height: 20px;
	width: 15px;
}
.about-desc {
	padding-right: 35px;
}

.border {
	background: #ffffff;
	border: 1px solid #a4a4a4;
	border-radius: 15px;
	margin-bottom: 16px;
}
.left {
	padding: 17px 20px 25px 27px;
	padding-bottom: 0;
}
.right {
	padding: 17px 20px 25px 27px;
}

.about-me-wrapper-desktop {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
}

#connect-visibility {
	border: 0;
	width: 100%;
}

#connect-lock-mobile {
	margin-top: -7%;
	margin-right: 2%;
	margin-left: -4%;
}

.choose-img {
	width: 98%;
}

#interest-modal {
	width: 100% !important;
	height: 100% !important;
	transform: translate(0%) !important;
	border-radius: 15px !important;
}

#comment-box {
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	margin-top: 30px;

	border-radius: 5px;
	padding-bottom: 115px;
}

.commnetlist {
	display: flex;
	/* justify-content: space-around; */
	align-items: center;
}
#comment-username {
	color: #617687;
	margin-right: 5px;
}

.comment-text {
	box-sizing: border-box;
	border-radius: 2px;
	background: #f5f5f5;
	border: 1px solid #c4c4c4;
	width: 85%;
	padding-left: 10px;
	padding-right: 10px;
}

.comment-and-date {
	display: none;
	font-size: 16px;
	line-height: 22px;
	color: #4a4a4a;
	padding-right: 40px;
}
#name-in-comment {
	font-size: 16px;
	line-height: 22px;
	color: #4a4a4a;
}
#name-in-comment-text {
	font-size: 16px;
	line-height: 22px;
	color: #4a4a4a;
	margin-bottom: -15px;
	margin-top: 10px;
}
.comment-mobile {
	display: inline-block;
}
.comment-desktop {
	display: none;
}
#comment-title-mobile {
	margin-left: 20px;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
}
#comment-img-mobile {
	height: 35px;
	width: 35px;
	border-radius: 100%
	/* margin-right: 16px; */
}

#comment-text-mobile {
	margin-left: 21px;
	margin-right: 19px;
}

#comment-mobile-flex {
	display: flex;
	/* justify-content: flex-start; */
	align-items: center;
	padding-left: 20px;
	margin-top: -15px;
}
.comment {
	display: flex;
	font-size: 16px;
	line-height: 19px;
	padding: 10px;
	margin-left: 10px;
	margin-right: 10px;
	align-items: flex-start;
}

.comment-img {
	height: 35px;
	margin-right: 20px;
	margin-top: 10px;
}

.comment-input {
	display: flex;
	margin-left: 20px;
	margin-right: 22px;
	align-items: center;
}
#write-a-comment {
	height: 30px;
	border: 1px solid #4a4a4a;
	box-sizing: border-box;
	border-radius: 5px;
	margin-right: 13px;
	width: 60%;
}
#comment-btn {
	height: 30px;
	width: auto;
	padding-right: 30px;
	padding-left: 30px;
	font-style: normal;
	font-size: 12px;
	line-height: 19px;
	text-align: center;
	color: #ffffff;
	background: #16636c;
	height: 34px;
}

.name-in-topic {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #4a4a4a;
	margin-top: 0;
	margin-bottom: 0;
}
.comment-date {
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
}

.post-date-desktop {
	display: none;
}

#modal-user {
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	padding-top: 7px;
	padding-bottom: 3px;
	color: #000000;
}

#text-date-mobile {
	margin-top: 10px;
}
.space-between {
	justify-content: space-between;
}
#lang {
	padding-top: 13px;
	margin-left: 10px;
	padding: 13px 10px 0 10px;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	font-weight: 700;
}
/* #success {
	width: 100%;
	display: flex;
} */
#mg-side {
	margin-left: 20px;
	margin-right: 20px;
}

#x {
	padding-top: 20px;
	padding-right: 20px;
	padding-left: 90%;
}
.badge {
	padding-right: 3px;
	padding-left: 3px;
	background: #be1123;
	color: white;
	font-weight: bold;
	font-size: 14px;
	margin-left: 6px;
}
#mobil-badge {
	margin-left: -10px;
}
#member-badge {
	margin-left: -97px;
	margin-bottom: 25px;
}
#name-NamePlusProfileImage {
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	margin-top: 25px;
	color: #16636c;
}
#no-underline {
	text-decoration: none !important;
}

.left-aboutme {
	padding-top: 20px;
	width: 80vw;
}

.right-aboutme {
	width: 80vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media (min-width: 415px) {
	.profile-img-name {
		display: flex;
		margin: 16px 10px;
	}

	#member-badge {
		margin-left: -47px;
	}
	/* .badge {
		margin-left: 100px;
	} */
	#mobil-badge {
		display: none;
	}
	#member-guideline {
		display: none;
	}
	#x {
		display: none;
	}
	#connection {
		margin-bottom: 0;
		padding-bottom: 16px;
	}

	.post-date {
		display: inline-block;
		margin-right: 30px;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #4a4a4a;
	}
	#desktop-desc {
		padding-right: 45px;
	}
	#comment-text {
		width: 100%;
		/* height: 66px;
		left: 0px;
		top: 129px;
		background: #ffffff;
		border: 1px solid #c4c4c4;
		box-sizing: border-box; */
	}

	.category {
		font-size: 18px;
	}
	/* #text-date-mobile {
		margin-left: 10px;
		margin-top: 10px;
	} */
	#post-visibility-text {
		font-size: 16px;
	}
	.comment-input {
		margin-left: 30px;
	}
	#write-a-comment {
		width: 69%;
		height: 34px;
		padding-right: 10px;
	}
	.comment-desktop {
		display: flex;
		padding-right: 24px;
		margin-top: 25px;
		width: 100%;
	}
	.comment-mobile {
		display: none;
	}
	.comment {
		display: flex;

		margin-left: 20px;
		padding: 10px;
		align-items: center;
	}
	.text-date {
		display: flex;
		align-content: stretch;
		justify-content: space-between;
		align-items: center;
		padding-right: 20px;
	}

	.text-date-topic {
		display: flex;
		align-content: stretch;
		justify-content: space-between;
		align-items: center;
		padding-right: 20px;
		margin-top: 16px;
	}
	#comment-img {
		margin-top: 25px;
		height: 50px;
		width: 50px;
		border-radius: 100%
	}
	#comment-title-text {
		margin-left: 20px;
		margin-top: -34px;
	}
	.subtitle {
		font-weight: bold;
		/* font-size: 20px; */
		line-height: 24px;
		/* margin-left: 20px; */
	}
	.CreateYourProfile {
		display: flex;
		justify-content: space-around;
	}
	.left {
		width: 28%;
		padding: 17px 20px 25px 27px;
	}
	.right {
		width: 28%;
		padding: 17px 20px 25px 27px;
	}
	.left-aboutme {
		/* padding-left: 15%;
		padding-right: 10%; */
		padding-top: 20px;
		width: 361px;
	}

	.right-aboutme {
		width: 361px;
	}

	#profile-lock {
		width: 15px;
		height: 20px;
		margin-right: 7px;
	}

	.visibility {
		margin-bottom: 26px;

		justify-content: space-evenly;
		align-items: center;
		align-content: stretch;
		padding: 7px 0px 7px 15px;
	}
	#create-profile-title {
		margin-left: 13%;
		font-weight: bold;
		font-size: 24px;
		line-height: 29px;
		color: #000000;
	}
	.post-date-desktop {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #4a4a4a;
		display: inline-flex;
	}
	#visibility-top {
		margin-left: 12%;
		/* padding-right: 20px !important; */
		min-width: 19% !important;
	}
	.addPicture {
		margin-left: 13%;
	}
	.country-option {
		width: 99%;
	}
	#visibility-text {
		font-size: 0.8rem;
	}

	#connect-visibility {
		width: 100%;
	}
	.about-me-mobile-page {
		display: none;
	}
	/* .about-mobile-page {
		display: none !important;
	} */

	#connect-visibility {
		width: 90%;
		margin-top: -20px;
		margin-left: -10px;
		margin-bottom: 5px;
	}

	.cntc-desktop {
		padding-right: 10px;
	}

	#connect-lock-desktop {
		margin-top: -37px;
		margin-right: 5px;
	}

	/* .about-desktop-page {
		/* position: absolute; */
		/* top: 50%; */
		/* left: 10%; */

	#add-modal {
		margin-left: 40px;
	}
	#add-goal-modal {
		margin-left: 0px;
	}
	#other-title {
		margin-left: 40px;
	}
	.ReactModal__Content.ReactModal__Content--after-open {
		width: 28% !important;
		left: 0% !important;
		right: 0% !important;
		margin: auto !important;
		top: 5% !important;
		height: 90% !important;
	}
	#interest-modal {
		width: 36% !important;
		transform: translate(70%, 10%) !important;
		left: 60% !important;
		height: 40% !important;
	}
	#add-interest {
		margin-left: 30px;
		margin-right: 10px;
	}
	#add-goal {
		margin-left: 30px;
		margin-right: 10px;
	}
	.comment-img {
		height: 50px;
		width: 50px;
		border-radius: 100%
	}
	#comment-image {
		margin-left: 20px;
	}
	.comment-and-date {
		display: inline-block;
	}
	.post-context {
		margin-left: 60px;
		margin-right: 60px;
		margin-top: 0;
	}
	.commentCount {
		padding-right: 60px;
	}

	.category-link {
		margin-left: 0;
	}

	.category-1st {
		margin-left: 0px;
	}
	#comment-category {
		margin-left: 34px;
	}
	.card-text-post {
		width: 100%;
	}
	.card-text-member {
		/* width: 100%; */
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
	}
	.parent {
		width: 100%;
	}
	#post-title-text {
		margin-left: 60px;
		margin-right: 30px;
	}
}
