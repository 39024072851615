@media screen and (min-width: 415px) {
	.left-member {
		padding: 3%;
		width: 45%;
	}
	.right-member {
		width: 45%;
		margin-top: 20px;
	}

	.member {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
		margin-left: 30px;
		margin-right: 50px;
	}
	#member-modal {
		width: 55% !important;
		background: #c4c4c4;
	}
}
